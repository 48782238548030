import React from 'react';
import './StudyAbroadLoan.css';

const StudyAbroadLoan = () => {
  return (
    <div className="study-abroad-loan-container">
      <div className="loan-info-container">
        <h1 className="abroad-heading">Study Abroad Education Loan</h1>
        <div className="loan-features">
          <div className="feature">
            <span className="icon">✓</span>
            <span>Non-Collateral</span>
          </div>
          <div className="feature">
            <span className="icon">✓</span>
            <span>Lowest Interest Rates</span>
          </div>
          <div className="feature">
            <span className="icon">✓</span>
            <span>Get Upto 1.5 Cr</span>
          </div>
          <div className="feature">
            <span className="icon">✓</span>
            <span>Zero Hidden Charges</span>
          </div>
          <div className="feature">
            <span className="icon">✓</span>
            <span>Quick Processing</span>
          </div>
          <div className="feature">
            <span className="icon">✓</span>
            <span>Safe & Secured</span>
          </div>
        </div>
        <div className="cta-container">
          <button className="btn best-service">Best Service</button>
          <button className="btn lenders">10+ Lenders</button>
        </div>
      </div>
      
      <div className="loan-image-container">
        <img src={`${process.env.PUBLIC_URL}/image/studyAbroad.png`} alt="Loan" />
      </div>
    </div>
  );
};

export default StudyAbroadLoan;