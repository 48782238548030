import React from 'react';
import LoanEligibility from './LoanEligibility';
import EducationLoanComponent from './EducationLoanComponent';
import Testimonials from '../../home/Testimonials';
import FAQ from '../../home/FAQSection';

const Eligilibilitys= () => {
  return (
    <div>
        <LoanEligibility />
        <EducationLoanComponent />
        <Testimonials />
        <FAQ />
    </div>
  );
}

export default Eligilibilitys;