import React from 'react';
import StudyAbroadLoan from './StudyAbroadLoan';
import LoanStats from '../belowHero/LoanStats';


function StudyAbroad() {
    return (
      <div className="StudyAbroad">
        <StudyAbroadLoan />
        <LoanStats />
      </div>
    );
  }

export default StudyAbroad;