import React from 'react';
import { motion } from 'framer-motion';
import './LoanStats.css';

const LoanStats = () => {
    const statsData = [
        {
            icon: (
                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z" />
                    <path d="m3.27 6.96 9 5.25 9-5.25" />
                    <path d="M12 22.08V12" />
                </svg>
            ),
            title: "Loan Partners",
            value: "10+",
            color: "#4a90e2"
        },
        {
            icon: (
                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <polyline points="23 6 13.5 15.5 8.5 10.5 1 18" />
                    <path d="M17 6h6v6" />
                </svg>
            ),
            title: "Loan Applications",
            value: "5000+",
            color: "#50c878"
        },
        {
            icon: (
                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <rect x="1" y="4" width="22" height="16" rx="2" ry="2" />
                    <line x1="1" y1="10" x2="23" y2="10" />
                </svg>
            ),
            title: "Loan Sanctioned",
            value: "₹ 2000 Cr+",
            color: "#ff6b6b"
        },
        {
            icon: (
                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                </svg>
            ),
            title: "Loan Disbursed",
            value: "₹ 1000 Cr+",
            color: "#9b59b6"
        }
    ];

    return (
        <motion.div
            className="loan-stats-container"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
        >
            {statsData.map((stat, index) => (
                <motion.div
                    key={index}
                    className="loan-stat"
                    whileHover={{ scale: 1.05 }}
                    transition={{ type: "spring", stiffness: 300 }}
                >
                    <div
                        className="stat-icon"
                        style={{
                            backgroundColor: stat.color,
                            color: 'white',
                            borderRadius: '50%',
                            display: 'inline-flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '12px',
                            marginBottom: '10px'
                        }}
                    >
                        {stat.icon}
                    </div>
                    <h3>{stat.title}</h3>
                    <p>{stat.value}</p>
                </motion.div>
            ))}
        </motion.div>
    );
};

export default LoanStats;