// import React from 'react';
// import './Header.css';

// const Header = () => {
//   const toggleMenu = () => {
//     const menuToggle = document.getElementById('menu-toggle');
//     const navLinks = document.querySelector('.nav-links');

//     menuToggle.checked = !menuToggle.checked;
//     navLinks.classList.toggle('open');
//   };

//   return (
//     <header className="header head-with-mainS">
//       {/* Use a direct path to the image in the public folder */}
//       <img src={`${process.env.PUBLIC_URL}/image/logo.png`} alt="Logo" className="logo" />
//       <nav>
//         <input type="checkbox" id="menu-toggle" className="menu-toggle" />
//         <label htmlFor="menu-toggle" className="menu-icon" onClick={toggleMenu}>&#9776;</label>
//         <ul className="nav-links">
//           {/* <li><a href="./"><b>Home</b></a></li> */}
//           <li><a href="./services"><b>Student Service</b></a></li>
//           <li><a href="./study-abroad"><b>Study Abroad</b></a></li>
//           <li><a href="./about-us"><b>About Us</b></a></li>
//           <li><a href="./contact-us"><b>Contact Us</b></a></li>
//           {/* New menu item for smartphone view */}
//           {/* <li className="smartphone-only"><a href="www.google.com"><b>New Item</b></a></li> */}
//         </ul>
//       </nav>
//     </header>
//   );
// };

// export default Header;

// src/components/Header.js
import React, { useState } from 'react';
import './Header.css';

const Header = () => {
  const [serviceOpen, setServiceOpen] = useState(false);
  // const [abroadOpen, setAbroadOpen] = useState(false);

  const toggleMenu = () => {
    const menuToggle = document.getElementById('menu-toggle');
    const navLinks = document.querySelector('.nav-links');
    menuToggle.checked = !menuToggle.checked;
    navLinks.classList.toggle('open');
  };

  const ArrowIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      viewBox="0 0 16 16"
      className="dropdown-icon"
    >
      <path
        fillRule="evenodd"
        d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
      />
    </svg>
  );

  return (
    <header className="header head-with-mainS">
      {/* <img src={`${process.env.PUBLIC_URL}/image/logo.png`} alt="Logo" className="logo" /> */}
      <li><a href="./"><img src={`${process.env.PUBLIC_URL}/image/logo.png`} alt="Logo" className="logo" /></a></li>
      <nav>
        <input type="checkbox" id="menu-toggle" className="menu-toggle" />
        <label htmlFor="menu-toggle" className="menu-icon" onClick={toggleMenu}>
          &#9776;
        </label>
        <ul className="nav-links">
          <li
            onMouseEnter={() => setServiceOpen(true)}
            onMouseLeave={() => setServiceOpen(false)}
            className="dropdown"
          >
            
              <b class="to-make-pointer">Student Service</b> <ArrowIcon />
            
            {/* <a href="./services">
              <b>Student Service</b> <ArrowIcon />
            </a> */}
            {serviceOpen && (
              <ul className="dropdown-content">
                <li><a href="./eligibility">Loan Eligibility</a></li>
                <li><a href="./education-loan">Education Loan</a></li>
                <li><a href="./insurance">Insurance</a></li>
                <li><a href="./emi-calculator">EMI Calculator</a></li>
              </ul>
            )}
          </li>

          {/* <li
            onMouseEnter={() => setAbroadOpen(true)}
            onMouseLeave={() => setAbroadOpen(false)}
            className="dropdown"
          >
            
              <b class="to-make-pointer">Study Abroad</b> <ArrowIcon />
            
            {abroadOpen && (
              <ul className="dropdown-content">
                <li><a href="./usa">USA</a></li>
                <li><a href="./uk">UK</a></li>
                <li><a href="./canada">Canada</a></li>
                <li><a href="./australia">Australia</a></li>
              </ul>
            )}
          </li> */}

          <li><a href="./study-abroad"><b>Study Abroad</b></a></li>
          <li><a href="./about-us"><b>About Us</b></a></li>
          <li><a href="./contact-us"><b>Contact Us</b></a></li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
