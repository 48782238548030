import React from 'react';
import { motion } from 'framer-motion';
import './LoanEligibility.css';

const LoanEligibility = () => {
  return (
    <div className="loan-eligibility-container">
      <div className="text-section">
        <h1 className="title">Loan Eligibility</h1>
        <p className="description">
          A tool that checks your eligibility for education loans for studying abroad and
          finds the best offer in the international student loan network.
        </p>
        <ul className="features">
          <li>
            <div className="icon-container">
              <motion.div
                className="icon icon-loan"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                {/* Replace with actual icon or SVG */}
                <span>✓</span>
              </motion.div>
            </div>
            <span>Loan offers like Students Choice</span>
          </li>
          <li>
            <div className="icon-container">
              <motion.div
                className="icon icon-cost"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                {/* Replace with actual icon or SVG */}
                <span>✓</span>
              </motion.div>
            </div>
            <span>Zero cost service</span>
          </li>
          <li>
            <div className="icon-container">
              <motion.div
                className="icon icon-check"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                {/* Replace with actual icon or SVG */}
                <span>✓</span>
              </motion.div>
            </div>
            <span>Hassle-free Eligibility check</span>
          </li>
          <li>
            <div className="icon-container">
              <motion.div
                className="icon icon-us-loan"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                {/* Replace with actual icon or SVG */}
                <span>✓</span>
              </motion.div>
            </div>
            <span>Unique US loan offers</span>
          </li>
        </ul>
      </div>
      <div className="image-section">
        <div className="image-circle">
          <img
            src={`${process.env.PUBLIC_URL}/image/service1.png`}
            alt="Loan Eligibility"
            className="image"
          />
        </div>
      </div>
    </div>
  );
};

export default LoanEligibility;
