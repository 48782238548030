import React from "react";
import { motion } from "framer-motion";
import { FaInfoCircle } from "react-icons/fa";
import "./InsurancePage.css"; // Import CSS file for styling

const InsurancePage = () => {
  const insuranceTypes = [
    { title: "Health Insurance", description: "Secure your family’s health with the best plans." },
    { title: "Life Insurance", description: "Ensure your loved ones’ future with financial stability." },
    { title: "Car Insurance", description: "Protect your vehicle against any damages or accidents." },
    { title: "Travel Insurance", description: "Travel without worries with our comprehensive plans." },
  ];

  const reasonsToChoose = [
    { title: "Wide Range of Options", description: "Compare policies from multiple providers in one place." },
    { title: "Affordable Premiums", description: "Get the best deals without compromising on coverage." },
    { title: "Easy Online Process", description: "Apply for policies quickly and easily with minimal paperwork." },
  ];

  return (
    <div className="insurance-page">
      {/* Header Section */}
      <header className="insurance-header">
        <h1>Compare & Buy Insurance</h1>
        <p>Choose the best plans for your needs</p>
        <motion.button
          className="cta-button"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
        >
          Get a Quote
        </motion.button>
      </header>

      {/* Insurance Types Section */}
      <section className="insurance-section">
        <h2>Insurance Types</h2>
        <div className="insurance-grid">
          {insuranceTypes.map((type, index) => (
            <motion.div
              key={index}
              className="insurance-card"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <FaInfoCircle className="insurance-icon" />
              <h3>{type.title}</h3>
              <p>{type.description}</p>
            </motion.div>
          ))}
        </div>
      </section>

      {/* Why Choose Us Section */}
      <section className="why-choose-us">
        <h2>Why Choose Us?</h2>
        <div className="reasons-grid">
          {reasonsToChoose.map((reason, index) => (
            <motion.div
              key={index}
              className="reason-card"
              whileHover={{ y: -5 }}
              whileTap={{ y: 0 }}
            >
              <h4>{reason.title}</h4>
              <p>{reason.description}</p>
            </motion.div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default InsurancePage;
