import React from "react";
import "./EducationLoanComponent.css";

const EducationLoanComponent = () => {
  return (
    <div className="education-loan-container">
      <div className="header-section">
        <blockquote className="quote">
          Unlocking global aspirations with <strong>Students Choice</strong>
          <br /> Your Global Education Partner.
        </blockquote>
      </div>
      <div className="content-section">
        <p className="description">
          Are you dreaming of pursuing an international education but facing financial constraints? Students Choice is your trusted partner on this educational journey. Here's why you should consider taking an education loan with us.
        </p>
        <div className="features">
          <div className="feature1">
            <h3>Trusted Expertise</h3>
            <p>
              Students Choice, an established study abroad consultancy in India, has fulfilled the global education dreams of over 50,000+ students.
            </p>
          </div>
          <div className="feature1">
            <h3>Strong Financing Partners</h3>
            <p>
              Our financing partners have sanctioned over 100+ Crore INR in education loans, ensuring financial support for your aspirations.
            </p>
          </div>
          <div className="feature1">
            <h3>Post-Pandemic Assistance</h3>
            <p>
              Despite challenges, we have facilitated numerous loan disbursements, helping students access quality education. Trust us for your educational financing needs.
            </p>
          </div>
          <div className="feature1">
            <h3>Comprehensive Support</h3>
            <p>
              Beyond loans, we offer comprehensive guidance, making Students Choice your one-stop destination for your international education journey.
            </p>
          </div>
        </div>
      </div>
      <div className="cta-section">
        <button className="cta-button">Start Your Journey</button>
      </div>
    </div>
  );
};

export default EducationLoanComponent;