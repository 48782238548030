import React from 'react';
import InsurancePage from './InsurancePage';
import FAQSection from '../../home/FAQSection';

const Insurance = () => {
  return (
    <div>
        <InsurancePage />
        <FAQSection />

    </div>
    );
};

export default Insurance;
