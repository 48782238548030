import React from 'react';
import EMICalculator from './EMICalculator';
import FAQSection from '../../home/FAQSection';

const EMI = () => {
  return (
    <div>
        <EMICalculator />
        <FAQSection />
    </div>
    );
};

export default EMI;
