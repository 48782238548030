import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { FaInfoCircle } from 'react-icons/fa';
import './EMICalculator.css';

const EMICalculator = () => {
  const [loanAmount, setLoanAmount] = useState('');
  const [interestRate, setInterestRate] = useState('');
  const [loanTenure, setLoanTenure] = useState('');
  const [downPayment, setDownPayment] = useState('');
  const [processingFee, setProcessingFee] = useState('');

  const [emi, setEmi] = useState(null);
  const [totalAmount, setTotalAmount] = useState(null);
  const [totalInterest, setTotalInterest] = useState(null);
  const [error, setError] = useState('');

  // Function to calculate EMI
  const calculateEMI = () => {
    // Input validation: check if required fields are filled
    if (!loanAmount || !interestRate || !loanTenure) {
      setError("Please fill in all required fields.");
      return;
    }

    // Convert input values to numbers
    const principal = parseFloat(loanAmount) - (parseFloat(downPayment) || 0) - (parseFloat(processingFee) || 0);
    const rate = parseFloat(interestRate) / 12 / 100; // Monthly interest rate
    const months = parseInt(loanTenure) * 12; // Loan tenure in months

    // Check if inputs are valid
    if (principal <= 0 || rate <= 0 || months <= 0) {
      setError("Please provide valid input values.");
      return;
    }

    // EMI calculation formula
    const emiAmount = (principal * rate * Math.pow(1 + rate, months)) / (Math.pow(1 + rate, months) - 1);
    const totalAmountToPay = emiAmount * months;
    const totalInterestPayable = totalAmountToPay - principal;

    // Update the state with calculated values
    setEmi(emiAmount.toFixed(2));
    setTotalAmount(totalAmountToPay.toFixed(2));
    setTotalInterest(totalInterestPayable.toFixed(2));
    setError(''); // Clear error message after successful calculation
  };

  return (
    <div className="emi-calculator-container">
      <div className="text-section">
        <h1 className="title">EMI Calculator</h1>
        <p className="description">
          Calculate your Equated Monthly Installment (EMI) and know your total payable amount for your loan.
        </p>

        {/* Error Message */}
        {error && <div className="error-message">{error}</div>}

        <div className="form-section">
          <div className="form-group">
            <label htmlFor="loanAmount">
              Loan Amount <FaInfoCircle className="form-label-icon" title="The total loan amount you wish to borrow" />
            </label>
            <input
              type="number"
              id="loanAmount"
              value={loanAmount}
              onChange={(e) => setLoanAmount(e.target.value)}
              placeholder="Enter Loan Amount"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="interestRate">
              Interest Rate (%) <FaInfoCircle className="form-label-icon" title="The annual interest rate on the loan" />
            </label>
            <input
              type="number"
              id="interestRate"
              value={interestRate}
              onChange={(e) => setInterestRate(e.target.value)}
              placeholder="Enter Interest Rate"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="loanTenure">
              Loan Tenure (in years) <FaInfoCircle className="form-label-icon" title="The duration of the loan in years" />
            </label>
            <input
              type="number"
              id="loanTenure"
              value={loanTenure}
              onChange={(e) => setLoanTenure(e.target.value)}
              placeholder="Enter Tenure in years"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="downPayment">
              Down Payment (Optional) <FaInfoCircle className="form-label-icon" title="The initial amount you pay upfront" />
            </label>
            <input
              type="number"
              id="downPayment"
              value={downPayment}
              onChange={(e) => setDownPayment(e.target.value)}
              placeholder="Enter Down Payment"
            />
          </div>

          <div className="form-group">
            <label htmlFor="processingFee">
              Processing Fee (Optional) <FaInfoCircle className="form-label-icon" title="The one-time fee charged by the lender" />
            </label>
            <input
              type="number"
              id="processingFee"
              value={processingFee}
              onChange={(e) => setProcessingFee(e.target.value)}
              placeholder="Enter Processing Fee"
            />
          </div>

          <motion.button
            className="calculate-btn"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={calculateEMI}
          >
            Calculate EMI
          </motion.button>
        </div>

        {/* Display EMI Result */}
        {emi && !error && (
          <div className="emi-result">
            <h3>EMI Result</h3>
            <p><strong>EMI: </strong>₹{emi}</p>
            <p><strong>Total Amount to Pay: </strong>₹{totalAmount}</p>
            <p><strong>Total Interest Payable: </strong>₹{totalInterest}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default EMICalculator;
