import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LandingPage from './components/home/landingPage';
import Header from './components/home/Header';
import './App.css';
import Footer from './components/footer/Footer';
import FooterMoving from './components/footer/FooterMoving';
import Eligibilitys from './components/services/eligibility/Eligilibilitys';
import EMI from './components/services/emi/EMI';
import Insurance from './components/services/insurance/Insurance';
import ContactUs from './components/contactUs/ContactUs';
import StudyAbroad from './components/studyAbroad/StudyAbroad';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route exact path="/" element={<LandingPage />} />
          <Route exact path="/eligibility" element={<Eligibilitys/>} />
          <Route exact path="/emi-calculator" element={<EMI/>} />
          <Route exact path="/insurance" element={<Insurance/>} />
          <Route exact path="/study-abroad" element={<StudyAbroad/>} />
          <Route path="/contact-us" element={<ContactUs/>} />

          {/* Add more routes here as needed */}
        </Routes>
        
        <Footer />
        <FooterMoving />
      </div>
    </Router>
  );
}

export default App;